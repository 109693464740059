<template>
  <!-- 头部轮博 -->
  <div class="lunbo">
    <el-carousel trigger="click" height="458px" arrow="never" :autoplay="true">
      <el-carousel-item v-for="(item, index) in imgs" :key="item.index">
        <div class="lunbo-content">
          <div class="lunbo-img" :style="{backgroundImage: 'url('+item.src+')'}"></div>
          <!-- <img :src="require(`@/assets/images/${item.src}`)" alt="" /> -->
          <div :class="item.class">
            <h1 v-show="item.title">{{ item.title }}</h1>
            <p>{{ item.content }}</p>
            <router-link :to="item.url" v-show="item.buttonText">{{
              item.buttonText
            }}</router-link>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgs: [
        {
          src: require("../assets/images/home-banner.png"),
          content:
            "致力于医疗大数据，AI辅助医疗，移动应用，解决医疗领域供需矛盾， 缔造医疗健康新格局，助力医疗健康事业高质量发展",
          class: "lunbo-AI lunbo-home",
          url: "",
        },
        {
          src: require("../assets/images/AI-banner.png"),
          title: "AI智能病历质控",
          content:
            "基于人工智能（AI）的智能病历质控系统，以“智能质控，闭环管理”为建设原则，通过对病历数据后结构化的分析挖掘，运用规范性、完整性、真实性、客观性、准确性和个性化的智能质控引擎对病历进行全面质控，智能识别病历缺陷，实现对临床医师病历书写过程的全流程管理，降低病历缺陷和纠纷隐患的发生率，全面提升医院病历质量和医疗质量的提升，实现病历质控从人工到人工智能的转变。",
          buttonText: "了解更多",
          class: "lunbo-AI",
          url: "/quality",
        },
        {
          src: require("../assets/images/auxiliary-banner.png"),
          title: "AI智能辅助诊疗",
          content:
            "依据患者医疗数据和专家知识，运用AI和知识推理引擎为医务工作者提供临床决策的智能应用系统。无缝衔接医院业务流程，深度耦合业务系统，在不干预医生诊疗流程行为下，实时为医务工作者提供最佳诊疗方案，有效提高医务工作者工作效率，提升临床的医疗质量，降低医疗风险。",
          class: "lunbo-AI lunbo-auxiliary",
          buttonText: "了解更多",
          url: "/diagnosis",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.lunbo {
  height: 458px;
  .lunbo-img {
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
  }
  img {
    width: 100%;
    height: 100%;
  }
  .lunbo-content {
    height: 458px;
    position: relative;
  }
  .lunbo-AI {
    position: absolute;
    width: 1200px;
    bottom: 122px;
    left: 50%;
    margin-left: -600px;
    top: 108px;
    h1 {
      font-size: 48px;
      font-weight: 500;
      color: #ffffff;
      margin-bottom: 16px;
      margin-top: 0;
    }
    p {
      font-size: 16px;
      width: 503px;
      color: rgba(255, 255, 255, 0.7);
      font-weight: 400;
      margin: 0;
    }
    a {
      display: inline-block;
      width: 158px;
      height: 50px;
      line-height: 50px;
      background: #fbb726;
      border-radius: 6px 6px 6px 6px;
      text-align: center;
      color: #ffffff;
      margin-top: 32px;
      &:hover {
        background: #ffcf68;
      }
    }
  }
  .lunbo-home {
    top: 181px;
    p {
      width: 406px;
      height: 102px;
      font-weight: 400;
      color: #ffffff;
      font-size: 24px;
      margin: 0;
    }
  }
  .lunbo-auxiliary{
    p{
      width: 480px;
    }
    a{
      margin-top: 40px;
    }
  }
}
</style>